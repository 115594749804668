<template>
  <div>
    <b-row>
      <b-col
        v-if="configuredJobs.length > 0"
        cols="12"
      >
        <b-card
          :title="`Configured jobs (${configuredJobs.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Latest result</th>
                  <th>Excluded size</th>
                  <th>Included size</th>
                  <th>Compression level</th>
                  <th>Backup repository</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="configuredJob in configuredJobs"
                  :key="configuredJob.id"
                >
                  <td>{{ configuredJob.name }}</td>
                  <td>{{ configuredJob.type | veeamJobTypeDisplayName(configuredJob.platform) }}</td>
                  <td>
                    <feather-icon
                      :class="getJobResultVariant(configuredJob.latestResult)"
                      icon="CircleIcon"
                    />
                  </td>
                  <td>{{ configuredJob.excludedSize | prettyBytes({binary: true}) }}</td>
                  <td>{{ configuredJob.includedSize | prettyBytes({binary: true}) }}</td>
                  <td>{{ configuredJob.compressionLevel | veeamJobCompressionLevelDisplayName }}</td>
                  <td>
                    {{ getBackupRepositoryTextForJobById(configuredJob.repositoryId) }}
                  </td>
                  <td>{{ configuredJob.description }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="localDisks.length > 0"
        cols="12"
      >
        <b-card
          :title="`Local disks (${localDisks.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Size</th>
                  <th>Free space</th>
                  <th>File system</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="localDisk in localDisks"
                  :key="localDisk.deviceId"
                >
                  <td>{{ localDisk.name }}</td>
                  <td>{{ localDisk.size | prettyBytes({binary: true}) }}</td>
                  <td>{{ localDisk.freeSpace | prettyBytes({binary: true}) }}</td>
                  <td>{{ localDisk.fileSystem }}</td>
                  <td>{{ localDisk.description }}</td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
      <b-col
        v-if="localDisks.length > 0"
        cols="12"
      >
        <b-card
          :title="`Backup repositories (${backupRepositories.length})`"
        >
          <div class="table-responsive config-table-wrapper">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Path</th>
                  <th>Total space</th>
                  <th>Free space</th>
                  <th>Description</th>
                  <th>Gateways</th>
                  <th style="min-width: 150px">
                    Flags
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="backupRepository in backupRepositories"
                  :key="backupRepository.id"
                >
                  <td>{{ backupRepository.name }}</td>
                  <td>{{ backupRepository.type | veeamRepositoryTypeDisplayName }}</td>
                  <td>{{ backupRepository.path }}</td>
                  <td>{{ backupRepository.totalSpace | prettyBytes({binary: true}) }}</td>
                  <td>{{ backupRepository.freeSpace | prettyBytes({binary: true}) }}</td>
                  <td>{{ backupRepository.description }}</td>
                  <td>{{ getGatwayNames(backupRepository) }}</td>
                  <td>
                    IsBusy = {{ backupRepository.isBusy ? 'Yes' : 'No' }}<br>
                    IsFull = {{ backupRepository.isFull ? 'Yes' : 'No' }}<br>
                    IsUnavailable = {{ backupRepository.isUnavailable ? 'Yes' : 'No' }}<br>
                    Immutability = {{ backupRepository.immutabilityEnabled ? 'Enabled' : 'Disabled' }}
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { BRow, BCol, BCard } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    configuredJobs() {
      if (this.asup.details && this.asup.details.configuredJobs) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.configuredJobs.sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      return []
    },
    localDisks() {
      if (this.asup.details && this.asup.details.localDisks) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.localDisks.sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      return []
    },
    backupRepositories() {
      if (this.asup.details && this.asup.details.backupRepositories) {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        return this.asup.details.backupRepositories.sort((a, b) => (a.name > b.name ? 1 : -1))
      }

      return []
    },
  },
  methods: {
    getGatwayNames(backupRepository) {
      if (backupRepository && backupRepository.gateways && backupRepository.gateways.length > 0) {
        return backupRepository.gateways.map(x => x.name).join(', ')
      }

      return null
    },
    getBackupRepositoryTextForJobById(backupRepositoryId) {
      if (!backupRepositoryId) {
        return null
      }

      const backupRepository = this.backupRepositories.find(x => x.id === backupRepositoryId)
      if (backupRepository) {
        let result = backupRepository.name
        if (backupRepository.path) {
          result += ` (${backupRepository.path})`
        }

        return result
      }

      return null
    },
    getJobResultVariant(jobResult) {
      switch (jobResult) {
        case -1: return 'info'
        case 0: return 'success'
        case 1: return 'warning'
        default: return 'danger'
      }
    },
  },
}
</script>

<style scoped>
  .config-table-wrapper {
    max-height: 400px;
    overflow-y: auto
  }

  .info {
    fill: var(--info);
    color: var(--info);
  }
  .success {
    fill: var(--success);
    color: var(--success);
  }
  .warning {
    fill: var(--warning);
    color: var(--warning);
  }
  .danger {
    fill: var(--danger);
    color: var(--danger);
  }
</style>
