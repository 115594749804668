import { render, staticRenderFns } from "./VeeamDetailConfiguration.vue?vue&type=template&id=8080cfa2&scoped=true&"
import script from "./VeeamDetailConfiguration.vue?vue&type=script&lang=js&"
export * from "./VeeamDetailConfiguration.vue?vue&type=script&lang=js&"
import style0 from "./VeeamDetailConfiguration.vue?vue&type=style&index=0&id=8080cfa2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8080cfa2",
  null
  
)

export default component.exports